<template>
  <div class="internal-payment-listing">
    <v-layout class="my-2">
      <v-flex md6>
        <div class="text-h5 color-custom-blue me-2">Payment</div>
      </v-flex>
      <v-flex md6>
        <div class="text-end">
          <v-btn
            depressed
            class="ml-2 custom-bold-button white--text"
            color="blue darken-4"
            tile
            @click="payment_dialog = true"
          >
            <v-icon>mdi-plus</v-icon>
            Create
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <ListingTable :columnCount="9" :rowData="rowData2">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">#</th>
            <th class="simple-table-th">Invoice No</th>
            <th class="simple-table-th">Invoice Title</th>
            <th class="simple-table-th">Date</th>
            <th class="simple-table-th">Payment Amount</th>
            <th class="simple-table-th">Invoice Amount</th>
            <th class="simple-table-th">Attachment</th>
            <th class="simple-table-th">Reference</th>
            <th class="simple-table-th">Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="rowData2.length">
            <tr
              v-for="(row, index) in rowData2"
              :key="index"
              :class="{ 'orange lighten-5': index % 2 }"
            >
              <td class="simple-table-td">
                {{ row.id }}
              </td>
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode><br />
                <Barcode :barcode="row.invoice_barcode"></Barcode>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="row?.invoice_title">
                    {{ row.invoice_title }}</template
                  >
                  <template v-else>
                    <em class="text-muted"> no title</em></template
                  >
                </p>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.invoice_date">
                  <b>Invoice: </b>
                  {{ formatDate(row.invoice_date) }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.payment_date">
                  <b>Payment: </b>
                  {{ formatDate(row.payment_date) }}
                </p>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Payment Amount: </b>
                  <template v-if="row.payment_amount">
                    {{ formatMoney(row.payment_amount) }}
                  </template>
                  <template v-else>
                    <em class="text-muted"> no payment amount</em></template
                  >
                </p>
              </td>
              <td class="simple-table-td">
                <div class="payment-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Invoice Amount: </b>
                    <template v-if="row.invoice_amount">
                      {{ formatMoney(row.invoice_amount) }}
                    </template>
                    <template v-else>
                      <em class="text-muted"> no Invoice amount</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Amount Received: </b>
                    <template v-if="row.invoices_collected_amount">
                      {{ formatMoney(row.invoices_collected_amount) }}
                    </template>
                    <template v-else>
                      <em class="text-muted"> no amount received</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Balance: </b>
                    <template v-if="row.invoice_balance">{{
                      formatMoney(row.invoice_balance)
                    }}</template>
                    <template v-else>
                      <em class="text-muted"> no balance</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="row.attachment">{{
                    row.attachment
                  }}</template>
                  <template v-else>
                    <em class="text-muted"> no attachment</em></template
                  >
                </p>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="row.reference">{{ row.reference }}</template>
                  <template v-else>
                    <em class="text-muted"> no reference</em></template
                  >
                </p>
              </td>

              <!-- <td class="simple-table-td">
                <div class="payment-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Received: </b>
                    {{ $accountingJS.formatMoney(row.amount) }}
                    <em class="text--secondary d-block" v-if="row.status == 4"
                      >( Partially Paid )</em
                    >
                    <em
                      class="text--secondary d-block"
                      v-else-if="row.status == 3"
                      >( Paid )</em
                    >
                    <em class="text--secondary d-block" v-else>( Unpaid )</em>
                  </p>
                </div>
              </td> -->
              <td class="simple-table-td">
                <TableActivity v-if="!lodash.isEmpty(row.added_by)" :data="row">
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no payment at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="paymentLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
    <PaymentCreate
      v-if="payment_dialog"
      :reassign-schedule-dialog="payment_dialog"
      v-on:close="payment_dialog = false"
    ></PaymentCreate>
  </div>
</template>

<script>
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { QUERY } from "@/core/services/store/request.module";
/*   import CustomStatus from "@/view/pages/partials/CustomStatus.vue"; */
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import PaymentCreate from "@/view/pages/partials/Payment-Create.vue";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      paymentLoading: false,
      payment_dialog: false,
      rowData2: [
        {
          id: 1,
          uuid: "57434f41-42a1-445a-9f70-ba7db4aeda46",
          barcode: "PAY0011",
          payment_amount: 300,
          payment_date: "2024-10-10",
          status: 2,
          reference: null,
          added_at: "2024-10-04 13:02:14",
          invoice_barcode: "INV0009",
          invoice_title: null,
          invoice_date: "2024-10-10",
          invoice_collected_amount: 600,
          invoices_amounts_detail:
            '{"amount": 1000.0, "paid_amount": 600.0, "collected_amount": 600.0}',
          proforma_approved_amount: '{"performa_approved": 600.0}',
          created_at: "3 weeks ago",
          invoices_collected_amount: 600,
          invoice_raised_amount: 1000,
          invoices_paid_amount: 600,
          invoice_balance: 0,
          p_amount_formatted: null,
          p_date_formatted: null,
          added_at_formatted: "04/10/2024 01:02 PM",
          updated_at_formatted: null,
          modified_at: null,
          attachment: null,
        },
      ],
      currentPage: 1,
      status: "all",
      search: null,
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0,
    };
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    typeId() {
      this.$nextTick(() => {
        this.getPayments();
      });
    },
  },
  methods: {
    clearFilter() {
      //
    },
    getPayments() {
      const data = {
        current_page: this.currentPage,
        entity: 0,
        search: this.search,
        status: this.status,
        type: this.type,
        type_id: this.typeId,
      };

      if (this.paymentLoading) {
        return false;
      }

      this.paymentLoading = true;

      this.$store
        .dispatch(QUERY, { url: "payment", data: data })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.currentPage = data.current_page;
          this.rowData = data.rows;
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.paymentLoading = false;
        });
    },
    routeToInvoice(row) {
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("payment.detail", {
            params: {
              id: row.id,
            },
          })
        );
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData2) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData2 ? this.rowData2.length : 0);
    },
  },
  mounted() {
    this.getPayments();
  },
  components: {
    Barcode,
    TableActivity,
    /*  CustomStatus, */
    ListingFooter,
    ListingTable,
    PaymentCreate,
  },
};
</script>
