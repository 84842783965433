<template>
  <Dialog :common-dialog="reassignScheduleDialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      Create Payment <span class="ms-2 orange--text">#PAY0057</span></template
    >
    <template v-slot:body>
      <v-container class="px-10" fluid>
        <v-form
          ref="mail_form"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="submit()"
        >
          <v-row>
            <v-col md="12">
              <label class="font-weight-700 font-size-16 required"
                >Invoice
              </label>
              <v-autocomplete
                dense
                color="cyan"
                filled
                id="invoice"
                :items="invoiceList"
                v-model="paymentData.invoice"
                placeholder="Select Invoice"
                solo
                flat
                item-color="cyan"
                item-text="name"
                item-value="id"
                hide-details
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="'No Invoice Found.'"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <label class="font-weight-700 font-size-16 required"
                >Payment Date
              </label>
              <DatePicker solo v-model="paymentData.payment_date"></DatePicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="py-0">
              <label class="font-weight-700 font-size-16">Reference </label>
            </v-col>
            <v-col md="6" class="py-0">
              <v-text-field
                label="Reference"
                readonly
                dense
                filled
                solo
                flat
                color="cyan"
                v-model="paymentData.reference"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <label class="font-weight-700 font-size-16"
                >Invoice Amount:
              </label>
              <ShowValue
                class="font-level-2-bold ms-2"
                :object="paymentData.invoice_amount"
                object-key="phase"
                label="Invoice Amount"
              >
              </ShowValue>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="py-0">
              <label class="font-weight-700 font-size-16"
                >Amount Received
              </label>
            </v-col>
            <v-col md="6" class="py-0">
              <v-text-field
                label="Amount Received"
                readonly
                dense
                filled
                solo
                flat
                color="cyan"
                v-model="paymentData.amount_received"
                prepend-inner-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <label class="font-weight-700 font-size-16"
                >Pending Amount:
              </label>
              <ShowValue
                class="font-level-2-bold ms-2"
                :object="paymentData.pending_amount"
                object-key="phase"
                label="Pending Amount"
              >
              </ShowValue>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <label class="font-weight-700 font-size-16">Balance: </label>
              <ShowValue
                class="font-level-2-bold ms-2"
                :object="paymentData.balance"
                object-key="phase"
                label="Balance"
              >
              </ShowValue>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <label
                class="font-weight-700 font-size-16"
                for="one-off-start-time"
                >Attachments</label
              >
              <table width="100%" style="table-layout: fixed">
                <tr
                  v-for="(row, index) in attachments"
                  :key="`create-visit-attachment-${index}`"
                >
                  <td class="px-0 py-0">
                    <v-file-input
                      hide-details
                      class="px-0"
                      dense
                      filled
                      solo
                      flat
                      v-model="row.file"
                      placeholder="Select File"
                      prepend-icon=""
                      prepend-inner-icon="$file"
                      v-on:change="updateFile(index, $event)"
                      color="cyan"
                    />
                  </td>
                  <td class="py-0">
                    <v-text-field
                      hide-details
                      class="px-0"
                      v-model="row.name"
                      placeholder="Name"
                      dense
                      filled
                      :suffix="row.ext ? `.${row.ext}` : ''"
                      solo
                      flat
                      color="cyan"
                    />
                  </td>
                  <td class="px-0 py-0" width="10%" align="center">
                    <v-btn
                      :disabled="attachments && attachments.length == 1"
                      v-on:click="removeFile(index)"
                      icon
                      class="w-100"
                      color="red lighten-1"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="px-0 pb-0" colspan="3">
                    <v-btn
                      color="cyan white--text"
                      class="custom-bold-button"
                      depressed
                      v-on:click="addMore"
                      tile
                      >Add More</v-btn
                    >
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>

          <!--  -->
        </v-form>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        depressed
        v-on:click="close_dialog()"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Close
      </v-btn>

      <v-btn
        depressed
        v-on:click="submit()"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        :disabled="!formValid || formLoading"
      >
        Save
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { POST, GET } from "@/core/services/store/request.module";
import ShowValue from "@/view/components/ShowValue";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    reassignScheduleDialog: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: 0,
    },
    updatedId: {
      type: Number,
      default: 0,
    },
    milestoneId: {
      type: Number,
      default: 0,
    },
    milestoneData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    updatedId: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param && param > 0) {
          this.getInvoice(param);
        }
      },
    },
    milestoneData: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.paymentData.invoice_amount =
            this.paymentData.invoice_amount &&
            this.paymentData.invoice_amount > 0
              ? this.paymentData.invoice_amount
              : param.amount;
        }
      },
    },
  },
  data() {
    return {
      formLoading: false,
      invoiceList: [],
      formValid: true,
      startDatePicker: false,
      statusList: [
        { value: "pending", text: "Pending", color: "red accent-4" },
        { value: "complate", text: "Complete", color: "green accent-4" },
      ],
      engineerList: [],
      attachments: [
        {
          file: null,
          name: null,
          suffix: null,
        },
      ],
      certificate: [
        {
          file: null,
          name: null,
          suffix: null,
        },
      ],
      milestone_invoice_id: 0,
      paymentData: {
        invoice: null,
        payment_date: null,
        reference: null,
        invoice_amount: null,
        invoice_received: null,
        pending_amount: null,
        balance: null,
        proforma_amount: null,
      },
    };
  },
  methods: {
    getInvoice(id) {
      if (id > 0) {
        this.milestone_invoice_id = id;
        const _this = this;
        _this.$store
          .dispatch(GET, { url: `milestone-invoice/${id}` })
          .then(({ data }) => {
            (_this.paymentData.invoice_date = data.invoice_date
              ? data.invoice_date
              : moment().format("YYYY-MM-DD")),
              (_this.paymentData.payment_status = data.payment_status
                ? data.payment_status
                : null),
              (_this.paymentData.collected_amount = data.collected_amount
                ? data.collected_amount
                : 0),
              (_this.paymentData.invoice_amount =
                data.invoice_amount && data.invoice_amount > 0
                  ? data.invoice_amount
                  : this.milestoneData.amount),
              (_this.paymentData.remark = data.remark ? data.remark : null),
              (_this.paymentData.milestone = data.milestoneId
                ? this.lodash.toSafeInteger(data.milestoneId)
                : 0),
              (_this.paymentData.project = data.projectId
                ? this.lodash.toSafeInteger(data.projectId)
                : 0);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
    getCollected() {
      if (
        Number(this.paymentData?.collected_amount) >
        Number(this.paymentData.invoice_amount)
      ) {
        this.paymentData.collected_amount = Number(
          this.paymentData.invoice_amount
        );
      }
    },
    close_dialog() {
      this.$emit("close", true);
    },
    updateFile(index, file) {
      if (file && file.name) {
        this.attachments[index].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].suffix = `.${file.name.split(".").pop()}`;
      } else {
        this.attachments[index].name = null;
        this.attachments[index].suffix = null;
      }
    },
    addMore() {
      this.attachments.push({
        file: null,
        name: null,
        suffix: null,
      });
    },
    removeFile(index) {
      this.attachments.splice(index, 1);
      /* if (this.attachments.length < 1) {
        this.addMore();
      } */
    },
    submit() {
      const _this = this;
      _this.formLoading = true;
      let formData = new FormData();
      if (this.paymentData && this.paymentData.invoice_date) {
        formData.append(
          "invoice_date",
          this.paymentData.invoice_date ? this.paymentData.invoice_date : null
        );
      }
      if (this.paymentData && this.paymentData.payment_status) {
        formData.append(
          "payment_status",
          this.paymentData.payment_status
            ? this.paymentData.payment_status
            : null
        );
      }
      if (this.paymentData && this.paymentData.remark) {
        formData.append(
          "remark",
          this.paymentData.remark ? this.paymentData.remark : null
        );
      }
      if (this.paymentData && this.paymentData.invoice_amount) {
        formData.append(
          "invoice_amount",
          this.paymentData.invoice_amount ? this.paymentData.invoice_amount : 0
        );
      }
      if (this.paymentData && this.paymentData.collected_amount) {
        formData.append(
          "collected_amount",
          this.paymentData.collected_amount
            ? this.paymentData.collected_amount
            : null
        );
      }

      formData.append("milestone", this.milestoneId ? this.milestoneId : 0);
      formData.append("project", this.projectId ? this.projectId : 0);
      if (this.attachments && this.attachments.length > 0) {
        for (let i = 0; i < this.attachments.length; i++) {
          if (
            this.attachments &&
            this.attachments[i] &&
            this.attachments[i].file
          ) {
            formData.append(
              `attachments[${i}][file]`,
              this.attachments[i].file
            );
            if (
              this.attachments &&
              this.attachments[i] &&
              this.attachments[i].name
            ) {
              formData.append(
                `attachments[${i}][name]`,
                this.attachments[i].name
              );
            }
          }
        }
      }
      let requestURL = "milestone-invoice";

      if (this.milestone_invoice_id) {
        requestURL = `milestone-invoice/${this.milestone_invoice_id}`;
      }
      _this.$store
        .dispatch(POST, {
          url: requestURL,
          data: formData,
        })
        .then(() => {
          _this.$emit("success", true);
          _this.close_dialog();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.paymentData.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 99) {
        let trimValue = finalval.substring(0, 100);
        this.paymentData.remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (this.paymentData.remark && this.paymentData.remark.length > 99) {
        e.preventDefault();
      }
    },
  },
  components: {
    Dialog,
    ShowValue,
    DatePicker,
  },
  mounted() {},
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
    todayDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
};
</script>
