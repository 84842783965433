<template>
  <DetailTemplate
    :customClass="'lead-detail detail-page'"
    v-if="getPermission('opportunity-approved:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1
            class="form-title custom-nowrap-ellipsis text-capitalize mb-0 me-2"
          >
            m11
          </h1>
          <v-chip label text-color="white" color="orange">Pending</v-chip>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-menu
        style="z-index: 99 !important"
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            :disabled="pageLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in milestoneMoreAction">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon :color="more.color">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        depressed
        :disabled="pageLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="createMilestone"
      >
        Edit <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        depressed
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="4">
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Project # :</label>
              </v-flex>
              <v-flex md8>
                <div v-if="milestoneArr.barcode">
                  <Barcode :barcode="milestoneArr.barcode"></Barcode>
                </div>
                <em v-else class="text--secondary">No project #</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16"
                  >Project Title :</label
                >
              </v-flex>
              <v-flex md8>
                <label
                  v-if="milestoneArr.project_title"
                  class="font-weight-600 font-size-16"
                  >{{ milestoneArr.project_title }}</label
                >
                <em v-else class="text--secondary">No project title</em>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="4">
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Date :</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="milestoneArr.date"
                  class="font-weight-600 font-size-16"
                  >{{ milestoneArr.date }}</label
                >
                <em v-else class="text--secondary">No date</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Amount :</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="milestoneArr.amount"
                  class="font-weight-600 font-size-16"
                  >{{ formatMoney(milestoneArr.amount) }}</label
                >
                <em v-else class="text--secondary">No amount</em>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="4">
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Start Date :</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="milestoneArr.start_date"
                  class="font-weight-600 font-size-16"
                  >{{ milestoneArr.start_date }}</label
                >
                <em v-else class="text--secondary">No start date</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">End Date :</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="lodash.isEmpty(milestoneArr.end_date) === false"
                  class="font-weight-600 font-size-16"
                  >{{ milestoneArr.end_date }}</label
                >
                <em v-else class="text--secondary">No end date</em>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="12">
            <v-tabs
              v-model="milestoneTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent"
            >
              <template v-for="(tab, index) in tabs">
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  :key="index"
                  :href="'#' + tab.key"
                  :disabled="tab.disabled"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  {{ tab.title }}
                </v-tab>
              </template>
            </v-tabs>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="height: 77vh; position: relative"
            >
              <v-tabs-items v-model="milestoneTab">
                <v-tab-item
                  v-if="getPermission('opportunity-approved:view')"
                  :value="'deliverable'"
                >
                  <Deliverable></Deliverable>
                </v-tab-item>
                <v-tab-item value="proformas"
                  ><Proformas isMilestoneDetail></Proformas>
                </v-tab-item>
                <v-tab-item value="invoice"
                  ><MilestoneInvoiceListing
                    isDetailMilestone
                  ></MilestoneInvoiceListing>
                </v-tab-item>
                <v-tab-item value="payment"><Payments></Payments> </v-tab-item>
              </v-tabs-items>
            </perfect-scrollbar>
          </v-col>
        </v-row>
      </v-container>
      <Dialog :commonDialog="milestoneDialog" :dialogWidth="900">
        <template v-slot:title
          ><template v-if="milestoneCreate.uuid">Update</template>
          <template v-else>Create</template> Milestones for Project</template
        >
        <template v-slot:body>
          <v-container fluid class="pt-0" v-if="milestoneDialog">
            <v-form
              ref="milestoneForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="createOrUpdate"
            >
              <v-row>
                <v-col md="12" class="pt-0">
                  <table class="width-100">
                    <tr>
                      <td colspan="2" class="py-2">
                        <label class="font-weight-500 font-size-16 required">
                          Name</label
                        >
                        <v-text-field
                          v-model.trim="milestoneCreate.name"
                          dense
                          filled
                          color="cyan"
                          label=" Name"
                          :disabled="dataLoading"
                          :loading="dataLoading"
                          solo
                          flat
                          :rules="[
                            validateRules.required(
                              milestoneCreate.name,
                              ' Name'
                            ),
                            validateRules.required(
                              milestoneCreate.name,
                              ' Name'
                            ),
                            validateRules.minLength(
                              milestoneCreate.name,
                              ' Name',
                              1
                            ),
                            validateRules.maxLength(
                              milestoneCreate.name,
                              ' Name',
                              100
                            ),
                          ]"
                        ></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="py-2">
                        <label class="font-weight-500 font-size-16">
                          Description</label
                        >
                        <v-textarea
                          v-model.trim="milestoneCreate.description"
                          auto-grow
                          dense
                          filled
                          :disabled="dataLoading"
                          :loading="dataLoading"
                          color="cyan"
                          label="Description"
                          solo
                          flat
                          :rules="[
                            validateRules.minLength(
                              milestoneCreate.description,
                              'Description',
                              1
                            ),
                            validateRules.maxLength(
                              milestoneCreate.description,
                              'Description',
                              100
                            ),
                          ]"
                          v-on:keypress="(e) => manageLimit(e)"
                          v-on:paste="(e) => onPaste(e)"
                          row-height="15"
                        ></v-textarea>
                        <div class="text-right">
                          {{
                            milestoneCreate.description
                              ? milestoneCreate.description.length
                              : 0
                          }}/100
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="py-2">
                        <label class="font-weight-500 font-size-16">Date</label>
                        <DatePicker :dataLoading="dataLoading" solo
                        :placeholder="'Date *'" v-model="milestoneCreate.date"
                        :default-date=" milestoneCreate.date " mandatory: true
                        />
                      </td>
                      <td class="py-2">
                        <label class="font-weight-500 font-size-16"
                          >Reminder Date
                        </label>
                        <DatePicker
                          :dataLoading="dataLoading"
                          solo
                          :default-date="milestoneCreate.reminder_date"
                          :placeholder="'Reminder Date '"
                          v-model="milestoneCreate.reminder_date"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td class="py-2" valign="top">
                        <label class="font-weight-500 font-size-16"
                          >Amount Type
                        </label>
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="amountTypeList"
                          :disabled="dataLoading"
                          :loading="dataLoading"
                          v-model.trim="milestoneCreate.amount_type"
                          label="Amount Type"
                          solo
                          flat
                          item-color="cyan"
                          item-text="text"
                          item-value="value"
                          hide-details
                          :rules="[
                            validateRules.required(
                              milestoneCreate.amount_type,
                              'Amount Type'
                            ),
                          ]"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Amount Type Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </td>
                      <td class="py-2">
                        <template v-if="milestoneCreate.amount_type == 1">
                          <label class="font-weight-500 font-size-16 required"
                            >Milestone Amount</label
                          >
                          <v-text-field
                            dense
                            color="cyan"
                            filled
                            :disabled="dataLoading"
                            :loading="dataLoading"
                            prepend-inner-icon="mdi-currency-usd"
                            hide-spin-buttons
                            v-on:keyup="changeMilestoneAmount"
                            type="number"
                            v-on:keypress="
                              limitDecimal($event, milestoneCreate.amount)
                            "
                            v-model="milestoneCreate.amount"
                            v-model.trim.number.lazy="milestoneCreate.amount"
                            label="Milestone Amount"
                            :rules="[
                              validateRules.required(
                                milestoneCreate.amount,
                                'Milestone Amount'
                              ),
                              validateRules.minLength(
                                milestoneCreate.amount,
                                'Milestone Amount',
                                1
                              ),
                              validateRules.maxLength(
                                milestoneCreate.amount,
                                'Milestone Amount',
                                12
                              ),
                            ]"
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                        </template>
                        <template v-if="milestoneCreate.amount_type == 2">
                          <label class="font-weight-500 font-size-16 required"
                            >Milestone Percentage</label
                          >
                          <v-text-field
                            dense
                            color="cyan"
                            filled
                            :disabled="dataLoading"
                            :loading="dataLoading"
                            append-icon="mdi-percent"
                            type="number"
                            @keypress="percentageKeyup"
                            @keyup="checkPercentage"
                            v-model="milestoneCreate.milestone_percentage"
                            v-model.trim.number.lazy="
                              milestoneCreate.milestone_percentage
                            "
                            label="Milestone Percentage"
                            :rules="[
                              validateRules.required(
                                milestoneCreate.milestone_percentage,
                                'Milestone Percentage'
                              ),
                              validateRules.minLength(
                                milestoneCreate.milestone_percentage,
                                'Milestone Percentage',
                                1
                              ),
                              validateRules.maxLength(
                                milestoneCreate.milestone_percentage,
                                'Milestone Percentage',
                                100
                              ),
                            ]"
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                        </template>
                        <p
                          class="text-black mt-1 pl-1 mb-1"
                          v-if="milestoneCreate.amount_type == 2"
                        >
                          <b
                            >Milestone Amount :
                            <span v-if="calculatedMilestoneAmountByPercentage">
                              {{
                                formatMoney(
                                  calculatedMilestoneAmountByPercentage
                                )
                              }}</span
                            >
                          </b>
                        </p>
                        <p class="text-black mt-1 pl-1 mb-1">
                          <b
                            >Project Balance :
                            <span v-if="milestoneCreate.amount_type == 1">
                              {{
                                formatMoney(
                                  projectBalance - milestoneCreate.amount
                                )
                              }}</span
                            >
                            <span v-else>
                              {{
                                formatMoney(
                                  projectBalance -
                                    calculatedMilestoneAmountByPercentage
                                )
                              }}</span
                            >
                          </b>
                        </p>
                        <p class="text-black pl-1 mb-0">
                          <b
                            >Project Value :
                            {{ formatMoney(detail.project_value) }}</b
                          >
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="py-2">
                        <label class="font-weight-500 font-size-16">
                          Attachments</label
                        >
                        <FileTemplate
                          :attachments="detail.attachments"
                          v-on:file:updated="updateFiles"
                          allowUpload
                        ></FileTemplate>
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>
        <template v-slot:action>
          <v-container class="py-0">
            <v-row>
              <v-col md="12" class="text-right">
                <v-btn
                  depressed
                  v-on:click="milestoneDialog = false"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                >
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  :disabled="!formValid || dataLoading"
                  :loading="dataLoading"
                  class="mx-2 custom-bold-button white--text"
                  v-on:click="checkInvoice"
                  color="cyan"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, POST, PUT } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import Deliverable from "@/view/pages/partials/Detail/Deliverable.vue";
import Proformas from "@/view/pages/partials/Detail/Proformas.vue";
import Payments from "@/view/pages/partials/MilestonePayment.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import MilestoneInvoiceListing from "@/view/pages/partials/MilestoneInvoiceListing.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "detail-costing",
  data() {
    return {
      projectCosting: 0,
      pageLoading: true,
      dataLoading: false,
      milestoneTab: "project",
      milestoneDialog: false,
      amountTypeList: [
        { value: 1, text: "Dollar" },
        { value: 2, text: "Percentage" },
      ],
      tabs: [
        {
          title: "Deliverable",
          icon: "mdi-phone",
          key: "deliverable",
          disabled: false,
        },
        {
          title: "Proforma",
          icon: "mdi-phone",
          key: "proformas",
          disabled: false,
        },
        {
          title: "Invoice",
          icon: "mdi-phone",
          key: "invoice",
          disabled: false,
        },
        {
          title: "Payment",
          icon: "mdi-phone",
          key: "payment",
          disabled: false,
        },
      ],
      milestoneMoreAction: [
        {
          title: "Pending",
          action: "pending",
          icon: "mdi-cancel",
          disabled: false,
          color: "red",
        },
        {
          title: "Completed",
          action: "completed",
          icon: "mdi-check-all",
          disabled: false,
          color: "green",
        },
      ],
      milestoneCreate: {
        project: 0,
        name: null,
        description: null,
        date: null,
        uuid: null,
        reminder_date: null,
        amount_type: 2,
        milestone_percentage: 0,
        amount: 0,
        project_balace: null,
        attachments: [],
      },
      milestoneArr: {
        barcode: "PR000001",
        project_title: "title",
        date: "20/10/2024",
        amount: 1000,
        start_date: "20/10/2024",
        end_date: "25/10/2024",
      },
    };
  },
  components: {
    DetailTemplate,
    FileTemplate,
    Deliverable,
    Proformas,
    Barcode,
    DatePicker,
    Dialog,
    MilestoneInvoiceListing,
    Payments,
  },
  methods: {
    setProjectBalance() {
      if (this.milestoneCreate && this.milestoneCreate.uuid) {
        this.projectBalance =
          Number(this.detail?.project_balance) +
          Number(this.milestoneCreate.amount);
      } else {
        this.projectBalance = Number(this.detail?.project_balance);
      }
    },
    createMilestone() {
      (this.milestoneCreate = {
        project: 0,
        name: null,
        description: null,
        /*  date: null, */
        uuid: null,
        /*    reminder_date: null, */
        amount_type: 2,
        milestone_percentage: 0,
        amount: 0,
        project_balace: null,
        attachments: [],
      }),
        (this.milestoneDialog = true);
      this.setProjectBalance();
    },
    checkInvoice() {
      if (
        this.singleMilestone &&
        this.singleMilestone.uuid &&
        this.milestoneCreate.amount != this.singleMilestone.amount &&
        this.singleMilestone.invoices
      ) {
        this.accept_dialog = true;
      } else {
        this.createOrUpdate();
      }
    },
    changeMilestoneAmount() {
      if (
        this.milestoneCreate?.amount < 0 ||
        this.milestoneCreate?.amount > this.project_balace
      ) {
        this.milestoneCreate.amount = 0;
      }
      if (this.milestoneCreate?.amount > this.detail.project_value) {
        this.milestoneCreate.amount = this.detail.project_value;
      }
    },
    percentageKeyup($event) {
      if (String(this.milestoneCreate.milestone_percentage).includes(".")) {
        let percentage = String(
          this.milestoneCreate.milestone_percentage
        ).split(".");
        if (percentage[1].length > 1) {
          $event.preventDefault();
        }
      }
      if (
        this.projectBalance <
        Number(this.milestoneCreate.milestone_percentage / 100) *
          Number(this.detail?.project_value)
      ) {
        this.milestoneCreate.milestone_percentage = 0;
      }
      const percentage =
        this.milestoneCreate.milestone_percentage + Number($event.key);
      if (percentage > 100) {
        $event.preventDefault();
      }
    },
    checkPercentage() {
      if (
        this.projectBalance <
        Number(this.milestoneCreate.milestone_percentage / 100) *
          Number(this.detail?.project_value)
      ) {
        this.milestoneCreate.milestone_percentage = 0;
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Percentage Can not be greater then remaining percentage."
          )
        );
        return false;
      }
    },
    getProjectMilestone() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "project/costing/" + _this.projectCosting,
        })
        .then(({ data }) => {
          _this.milestoneArr = {
            barcode: data.barcode,
            pdf_url: data.pdf_url,
            reference: data.reference,
            soled_job_no: data.soled_job_no,
            customer: data.customer,
            project: data.project,
            quotation: data.quotation,
            customer_po_no: data.customer_po_no,
            customer_po_date: data.customer_po_date,
            prepared_by: data.prepared_by,
            quotation_barcode: data.quotation_barcode,
            vo: data.vo,
            project_po: data.project_po,
            project_vo: data.project_vo,
            total_price: data.total_price,
            total_cost: data.total_cost,
            profit: data.profit,
            profit_percentage: data.profit_percentage,
            created_at: data.created_at,
            modified_at: data.modified_at,
          };
          _this.costingItems = [];
          for (let i = 0; i < data.items.length; i++) {
            _this.costingItems.push({
              supplier: data.items[i].company_name,
              supplier_barcode: data.items[i].supplier_quote,
              description: data.items[i].description,
              amount: data.items[i].amount,
              remarks: data.items[i].remarks,
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    createOrUpdate() {
      const _this = this;
      if (!_this.$refs.milestoneForm.validate()) {
        return false;
      }
      (_this.milestoneCreate.amount =
        this.milestoneCreate.amount_type == 1
          ? _this.milestoneCreate.amount
          : Number(_this.milestoneCreate.milestone_percentage)),
        (_this.milestoneCreate.project = _this.detail.id);
      let url = "";
      let requst = "";

      if (_this.milestoneCreate.amount <= 0) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Amonut Can not be less then zero.")
        );
        return false;
      }
      if (_this.milestoneCreate.uuid) {
        url = `project-milestone/${_this.milestoneCreate.uuid}`;
        requst = PUT;
      } else {
        url = `project-milestone/`;
        requst = POST;
      }
      _this.dataLoading = true;
      _this.$store
        .dispatch(requst, {
          url: url,
          data: _this.milestoneCreate,
        })
        .then(() => {
          _this.accept_dialog = false;
          _this.$emit("reloadData", true);
          _this.getAllMilestones();
          _this.milestoneDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    getSingle(uuid) {
      if (uuid) {
        const _this = this;
        _this.milestoneDialog = true;
        _this.$store
          .dispatch(GET, { url: `project-milestone-single/${uuid}` })
          .then(({ data }) => {
            _this.singleMilestone = data;
            _this.singleMilestone.amount = _this.lodash.toSafeInteger(
              _this.singleMilestone.amount
            );
            _this.singleMilestone.amount_type = _this.lodash.toSafeInteger(
              _this.singleMilestone.amount_type
            );
            _this.milestoneCreate.uuid = _this.singleMilestone.uuid;
            (_this.milestoneCreate.name = _this.singleMilestone.name
              ? _this.singleMilestone.name
              : null),
              (_this.milestoneCreate.description = _this.singleMilestone
                .description
                ? _this.singleMilestone.description
                : null),
              (_this.milestoneCreate.date = _this.singleMilestone.date
                ? _this.singleMilestone.date
                : moment().format("YYYY-MM-DD")),
              (_this.milestoneCreate.reminder_date = _this.singleMilestone
                .reminder_date
                ? _this.singleMilestone.reminder_date
                : moment().format("YYYY-MM-DD")),
              (_this.milestoneCreate.amount = _this.singleMilestone.amount
                ? _this.lodash.toSafeInteger(_this.singleMilestone.amount)
                : null),
              (_this.milestoneCreate.milestone_percentage = _this
                .singleMilestone.progress
                ? _this.singleMilestone.progress
                : null),
              (_this.milestoneCreate.amount_type = _this.singleMilestone
                .amount_type
                ? this.lodash.toSafeInteger(_this.singleMilestone.amount_type)
                : 1);
            _this.setProjectBalance();
            /*  this.$emit('reloadData',true); */
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }
    },
  },
  // props: {
  //   detail: {
  //     type: Object,
  //     required: true,
  //     default: () => {
  //       return new Object();
  //     },
  //   },
  //   type: {
  //     type: String,
  //     required: true,
  //     default: "product",
  //   },
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Opportunity Approved", route: "opportunity-approved" },
      { title: "Milestone" },
      { title: "Detail" },
    ]);
  },
  // created() {
  //   this.projectCosting = this.lodash.toSafeInteger(this.$route.params.id) || 0;
  //   if (this.projectCosting > 0) {
  //     this.getProjectMilestone();
  //   } else {
  //     this.goBack();
  //   }
  // },
  computed: {
    calculatedMilestoneAmountByPercentage() {
      return (
        Number(this.milestoneCreate.milestone_percentage / 100) *
        Number(this.detail && this.detail.project_value)
      );
    },
    customerName() {
      return this.lodash.isEmpty(this.milestoneArr.customer) === false
        ? this.milestoneArr.customer.company_name
        : null;
    },
    pageTitle() {
      return "Opportunity Approved Milestone For " + this.customerName;
    },
  },
};
</script>
