<template>
  <!--begin::Ticket Internal Listing-->
  <v-container fluid>
    <v-layout>
      <v-flex
        class="pt-0 justify-flex-start d-flex align-center margin-auto pb-2"
        ><div class="text-h5 color-custom-blue me-2">Deliverable</div>
        <v-chip class="mx-1" outlined label color="orange">
          <span class="text-h6"
            >Total Items: {{ deliverableData.length }}</span
          ></v-chip
        >
        <v-chip class="mx-1" outlined label color="green">
          <span class="text-h6"
            >Total Quantity to be Delivered:
            {{ total_qty_to_be_delivered }}</span
          ></v-chip
        >
        <v-chip class="mx-1" outlined label color="blue darken-4">
          <span class="text-h6">Total GAP: {{ total_gap }}</span></v-chip
        >
      </v-flex>
      <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
        <!-- <AutoCompleteInput
          v-if="quotationList"
          hide-details
          :items="quotationList"
          id="quotation"
          placeholder="Quotation"
          item-text="barcode"
          @change="quotationFilterChange"
          item-value="id"
          v-model="quotationFilter"
          outlined
          style="max-width: 200px"
        >
        </AutoCompleteInput> -->
        <v-autocomplete
          :items="quotationList"
          v-model="quotationFilter"
          hide-details
          placeholder="Select Quotation"
          dense
          filled
          solo
          flat
          color="cyan"
          style="max-width: 200px"
          @change="quotationFilterChange"
        />
        <v-btn
          depressed
          class="mx-2 custom-bold-button custom-btn-color white--text"
          tile
          :loading="dataLoading"
          :disabled="!showGenerateProformaBTn"
          @click="saveDeliverable"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          class="mx-2 custom-bold-button custom-btn-color white--text"
          tile
          :loading="dataLoading"
          v-on:click="generateProforma"
          :disabled="!showGenerateProformaBTn"
        >
          Generate Proforma Request
        </v-btn>
      </v-flex>
    </v-layout>

    <ListingTable
      :columnCount="9"
      :dataLoading="dataLoading"
      :rowData="deliverableData"
    >
      <template v-slot:thead>
        <thead style="position: sticky; top: 0; z-index: 5">
          <tr>
            <th class="simple-table-th" width="5%">
              <v-checkbox
                v-model="selectAllItems"
                dense
                hide-details
                v-if="deliverableData.length"
                :disabled="!deliverableData.length"
                class="mt-2 shrink"
                @change="selectAllLineItems"
              ></v-checkbox>
            </th>
            <th class="simple-table-th" width="10%">Quotation #</th>
            <!-- <th class="simple-table-th" width="10%">Status</th> -->
            <th class="simple-table-th" width="10%">Name</th>
            <th class="simple-table-th" width="10%">Description</th>
            <th class="simple-table-th" width="10%">Price</th>
            <th class="simple-table-th" width="5%">Uom</th>
            <th class="simple-table-th" width="10%">Quotation Qty</th>
            <th class="simple-table-th" width="10%">Quotation Qty GAP</th>
            <th class="simple-table-th" width="10%">Qty to be Delivered</th>
            <th class="simple-table-th" width="10%">GAP (Delivered Balance)</th>
            <th class="simple-table-th" width="10%">Qty to be Delivered</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="deliverableData.length > 0">
            <tr :key="index" v-for="(data, index) in deliverableData">
              <td class="simple-table-td" width="5%">
                <div class="px-2">
                  <v-checkbox
                    dense
                    v-model="data.is_check"
                    @change="checkboxChange"
                  ></v-checkbox>
                </div>
              </td>

              <td class="simple-table-td" width="10%">
                <div><Barcode :barcode="data.quotation_barcode"></Barcode></div>
              </td>
              <!-- <td class="simple-table-td" width="10%">
                <template v-if="data && data.quotation_status">
                  <v-chip
                    small
                    :color="data.quotation_status == 2 ? 'green' : 'red'"
                    text-color=""
                    outlined
                    label
                  >
                    <template v-if="data.quotation_status == 2">
                      Approved
                    </template>
                    <template v-else> Rejected </template>
                  </v-chip>
                </template>
              </td> -->
              <td class="simple-table-td" width="10%">
                <div v-if="data && data.line_item_name">
                  {{ data.line_item_name }}
                </div>
                <em v-else>no name</em>
              </td>
              <td class="simple-table-td" width="10%">
                <div v-if="data && data.description">
                  {{ data.description }}
                </div>
                <em class="text-muted" v-else>no description</em>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.line_item_total">
                    <span class="font-weight-700 font-size-16">{{
                      formatMoney(data.line_item_total)
                    }}</span>
                  </template>
                </p>
              </td>
              <td class="simple-table-td" width="5%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.line_item_uom">
                    {{ data.line_item_uom }}
                  </template>
                </p>
              </td>

              <td
                class="simple-table-td"
                width="10%"
                style="background: aliceblue"
              >
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="blue--text" v-on="on" v-bind="attrs">
                        {{ data.line_item_quantity }}
                      </span>
                    </template>
                    <span>Quotation Qty</span>
                  </v-tooltip>
                </p>
              </td>
              <td
                class="simple-table-td"
                width="10%"
                style="background: aliceblue"
              >
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs">
                        {{ data.all_milestones_qty_gap }}
                      </span>
                    </template>
                    <span>All Milestone Qty GAP</span>
                  </v-tooltip>
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <div>
                  <v-text-field
                    v-model="data.qty_to_be_delivered"
                    hide-detials
                    placeholder="Qty to be Delivered"
                    dense
                    filled
                    solo
                    flat
                    color="cyan"
                    type="number"
                    :readonly="!data.is_check"
                  ></v-text-field>
                </div>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="blue--text" v-on="on" v-bind="attrs">
                        {{ data.gap }}
                      </span>
                    </template>
                    <span>GAP</span>
                  </v-tooltip>
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="green--text" v-on="on" v-bind="attrs">
                        {{ data.qty_to_be_delivered }}
                      </span>
                    </template>
                    <span>QTY to be Delivered</span>
                  </v-tooltip>
                </p>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="9">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no deliverable at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <DeleteDialog :delete-dialog="isDeliveryExistDialog">
      <template v-slot:title> Confirmation</template>
      <template v-slot:text>
        <div class="d-flex align-center">
          <v-icon color="orange" size="60">mdi-information-outline</v-icon>
          <div class="ms-4">
            <div class="font-size-16 font-weight-500 grey--text">
              Is there any delivery?
            </div>
            <h3 class="font-size-16 font-weight-500 delete-text">
              Note: If you want to generate proforma without delivery go to
              Proforma Section.
            </h3>
          </div>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="isDeliveryExistDialog = false"
          >No! Close</v-btn
        >
        <v-btn
          depressed
          :disabled="dataLoading"
          :loading="dataLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes!</v-btn
        >
      </template>
    </DeleteDialog>
  </v-container>
  <!--end::Ticket Internal Listing-->
</template>
  
  <script>
import moment from "moment-timezone";
import { DELETE } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
//import AutoCompleteInput from "@/view/components/AutoCompleteInput";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "deliverable-list",
  data() {
    return {
      dataLoading: false,
      selectAllItems: false,
      quotationFilter: null,
      isDeliveryExistDialog: false,
      quotationList: ["QT0056"],
      showGenerateProformaBTn: false,
      isDelivered: false,
      selectedLineItems: [],
      deliverableData: [
        {
          id: 1,
          is_check: false,
          line_item: 10255,
          line_item_uom: "unit",
          line_item_quantity: 210,
          line_item_total: 10000,
          line_item_product_type: "inventory",
          line_item_name: "Laptop",
          description: null,
          quotation_barcode: "QT0056",
          quotation_status: 2,
          quotation: 59,
          qty_to_be_delivered: 0,
          deliverable_id: 34,
          already_delivered_qty: 0,
          all_milestones_total_qty_to_be_delivered: 0,
          line_item_total_formatted: 15000,
          qty_deliver: 0,
          gap: 0,
          default_gap: 0,
          all_milestones_qty_gap: 210,
          quotation_status_value: "approved",
        },
        {
          id: 2,
          is_check: false,
          line_item: 10255,
          line_item_uom: "unit",
          line_item_quantity: 100,
          line_item_total: 10000,
          line_item_product_type: "inventory",
          line_item_name: "Laptop",
          quotation_barcode: "QT0057",
          quotation_status: 2,
          quotation: 59,
          qty_to_be_delivered: 50,
          deliverable_id: 34,
          already_delivered_qty: 50,
          all_milestones_total_qty_to_be_delivered: 50,
          line_item_total_formatted: 10000,
          qty_deliver: 50,
          gap: 0,
          default_gap: 0,
          all_milestones_qty_gap: 50,
          quotation_status_value: "approved",
        },
      ],
    };
  },
  props: {},
  methods: {
    createMilestone() {},
    quotationFilterChange() {},
    saveDeliverable() {},
    isAnyLineItemsSelected() {
      this.showGenerateProformaBTn = this.deliverableData.some(
        (ele) => ele.is_check
      );
    },
    isAllLineItemsSelected() {
      this.selectAllItems = this.deliverableData.every((ele) => ele.is_check);
      this.isDelivered = true;
    },
    checkboxChange(value, index) {
      this.isAnyLineItemsSelected();
      this.isAllLineItemsSelected();
      if (value) {
        this.selectedLineItems.push(this.deliverableData[index]);
      } else {
        this.selectedLineItems.splice(index, 1);
      }
    },
    selectAllLineItems() {
      this.deliverableData.forEach((ele) => {
        ele.is_check = this.selectAllItems;
      });
      this.isAnyLineItemsSelected();
    },
    generateProforma() {
      this.isDeliveryExistDialog = true;
    },
    deleteConfirm() {
      this.dataLoading = true;
      this.$store
        .dispatch(DELETE, { url: `project-milestone/${this.deleteUuid}` })
        .then(() => {
          this.deleteUuid = null;
          this.isDeliveryExistDialog = false;
          this.deleteMilestoneName = null;
          this.getAllMilestones();
          this.$emit("reloadData", true);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
  },
  computed: {
    total_qty_to_be_delivered() {
      let qty_to_be_delivered = 0;
      this.deliverableData.forEach((ele) => {
        if (ele.qty_to_be_delivered) {
          qty_to_be_delivered += ele.qty_to_be_delivered;
        }
      });
      return qty_to_be_delivered;
    },
    total_gap() {
      let gap = 0;
      this.deliverableData.forEach((ele) => {
        if (ele.gap) {
          gap += ele.gap;
        }
      });
      return gap;
    },
  },
  components: {
    Barcode,
    ListingTable,
    DeleteDialog,
    //AutoCompleteInput,
  },
};
</script>

  